/**
 * Item Routes
 */
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Privacy from './Privacy';
import Terms from './Terms';
import AppInfo from './AppInfo';
import Footer from './Footer';

const InfoComponent = ({match}) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/privacy`}/>
        <Route path={`${match.url}/privacy`} component={Privacy}/>
        <Route path={`${match.url}/terms`} component={Terms}/>
        <Route path={`${match.url}/app`} component={AppInfo}/>
        <Route path={`${match.url}/footer`} component={Footer}/>
    </Switch>
);

export default InfoComponent;
