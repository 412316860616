/**
 * Sidebar Content
 */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {List, ListSubheader} from '@material-ui/core';
import {withRouter, NavLink} from 'react-router-dom';
import NavMenuItem from './NavMenuItem';
import {onToggleMenu} from 'Store/Actions';

function SidebarContent() {
    const dispatch = useDispatch();
    const sidebar = useSelector(state => state.sidebar);
    const {sidebarMenus} = sidebar;

    const toggleMenu = (menu, stateCategory) => {
        let data = {menu, stateCategory}
        dispatch(onToggleMenu(data));
    }

    return (
        <div className="rct-sidebar-nav">
            <nav className="navigation">
                <List
                    className="rct-mainMenu p-0 m-0 list-unstyled"
                    subheader={
                        <ListSubheader className="side-title" component="li">
                            <span>게시글</span>
                        </ListSubheader>}
                >
                    {sidebarMenus.category1.map((menu, key) => (
                        <NavMenuItem
                            menu={menu}
                            key={key}
                            onToggleMenu={() => toggleMenu(menu, 'category1')}
                        />
                    ))}
                </List>
                <List
                    className="rct-mainMenu p-0 m-0 list-unstyled"
                    subheader={
                        <ListSubheader className="side-title" component="li">
                            <span>회원</span>
                        </ListSubheader>}
                >
                    {sidebarMenus.category2.map((menu, key) => (
                        <NavMenuItem
                            menu={menu}
                            key={key}
                            onToggleMenu={() => toggleMenu(menu, 'category2')}
                        />
                    ))}
                </List>
                <List
                    className="rct-mainMenu p-0 m-0 list-unstyled"
                    subheader={
                        <ListSubheader className="side-title" component="li">
                            <span>앱 관리</span>
                        </ListSubheader>}
                >
                    {sidebarMenus.category3.map((menu, key) => (
                        <NavMenuItem
                            menu={menu}
                            key={key}
                            onToggleMenu={() => toggleMenu(menu, 'category3')}
                        />
                    ))}
                </List>
                <List
                    className="rct-mainMenu p-0 m-0 list-unstyled"
                    subheader={
                        <ListSubheader className="side-title" component="li">
                            <span>사이트 관리</span>
                        </ListSubheader>}
                >
                    {sidebarMenus.category4.map((menu, key) => (
                        <NavMenuItem
                            menu={menu}
                            key={key}
                            onToggleMenu={() => toggleMenu(menu, 'category3')}
                        />
                    ))}
                </List>

            </nav>
        </div>
    );
}

export default withRouter(SidebarContent);
