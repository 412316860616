import React, {useState, useEffect} from "react";
import {Button} from '@material-ui/core';
import {Input} from 'reactstrap';
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

LinkItem.defaultProps = {
    placeholder: '주소를 입력하세요'
}

function LinkItem({title, values, placeholder}) {
    const [text, setText] = useState(values[title] || '');

    useEffect(() => {
        setText(values[title] || '');
    }, [title, values])

    const onSave = () => {
        Apis.linkSave(title, text).then((resp) => {
            NotificationManager.success('저장되었습니다.');
        }).catch((e) => {});
    }

    return (
        <div className={'link-item'}>
            <span className={'fs-14 text-base'}>{title}</span>
            <Input
                className={'fs-13'} placeholder={placeholder}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Button variant={'contained'} size={'small'} className={'bg-light-blue text-white'} onClick={onSave}>저장</Button>
        </div>
    )
}

export default LinkItem