import React, {useState, useEffect} from "react";
import {Dialog, DialogContent, DialogActions, RadioGroup, Radio, FormControlLabel, Button} from "@material-ui/core";
import DialogTitle from "Components/Dialog/Title";
import {Input} from 'reactstrap';
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import moment from "moment";
import JSONParseDefault from "json-parse-default";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

function AnswerQnaModal({open, id, onClose}) {
    const [qnaPublic, setQnaPublic] = useState(true);
    const [qnaTitle, setQnaTitle] = useState('');
    const [qnaResidentialArea, setQnaResidentialArea] = useState('');
    const [qnaSex, setQnaSex] = useState('');
    const [qnaAge, setQnaAge] = useState('');
    const [qnaWorkType, setQnaWorkType] = useState('WORKER');
    const [qnaContractTerm, setQnaContractTerm] = useState('REGULAR');
    const [qnaContractPeriod, setQnaContractPeriod] = useState('');
    const [qnaWorkerNum, setQnaWorkerNum] = useState('OVER_5');
    const [qnaWorkRange, setQnaWorkRange] = useState('');
    const [qnaWorkTime, setQnaWorkTime] = useState('');
    const [qnaBusinessPlace, setQnaBusinessPlace] = useState('');
    const [qnaBusinessType, setQnaBusinessType] = useState('');
    const [qnaPhone, setQnaPhone] = useState('');
    const [qnaConsulting, setQnaConsulting] = useState('DELAY_WAGE');
    const [qnaContent, setQnaContent] = useState('');
    const [qnaFiles, setQnaFiles] = useState([]);
    const [createdAt, setCreatedAt] = useState();
    const [username, setUsername] = useState('');
    const [qnaStatus, setQnaStatus] = useState('PENDING');
    const [qnaAnswer, setQnaAnswer] = useState('');

    useEffect(() => {
        if (open) {
            getData();
            setCreatedAt();
        }
    }, [open]);

    const getData = () => {
        Apis.qnaInfo(id).then((resp) => {
            setQnaPublic(resp.qnaPublic)
            setQnaTitle(resp.qnaTitle)
            setQnaResidentialArea(resp.qnaResidentialArea);
            setQnaSex(resp.qnaSex);
            setQnaAge(resp.qnaAge);
            setQnaWorkType(resp.qnaWorkType);
            setQnaContractTerm(resp.qnaContractTerm)
            setQnaContractPeriod(resp.qnaContractPeriod);
            setQnaWorkerNum(resp.qnaWorkerNum);
            setQnaWorkRange(resp.qnaWorkRange);
            setQnaWorkTime(resp.qnaWorkTime);
            setQnaBusinessPlace(resp.qnaBusinessPlace);
            setQnaBusinessType(resp.qnaBusinessType);
            setQnaPhone(resp.qnaPhone)
            setQnaConsulting(resp.qnaConsulting);
            setQnaContent(resp.qnaContent);
            setCreatedAt(resp.createdAt);
            setUsername(resp.username);
            setQnaStatus(resp.qnaStatus);
            setQnaContent(resp.qnaContent);
            setQnaFiles(JSONParseDefault(resp.qnaFiles, null, []));
            setQnaAnswer(resp.qnaAnswer || '');
        }).catch(() => {
        });
    }

    const onSave = () => {
        Apis.qnaAnswer(id, qnaAnswer).then((resp) => {
            NotificationManager.success('저장되었습니다.');
            onClose();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        })
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'}>
            <DialogTitle title={'상담내역'} onClose={onClose}/>
            <DialogContent style={{width: 1000, height: 550}}>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <div className={'fs-16 ff-dream4 border-bottom'}>질문</div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>제목</span>
                        <span className={'fs-14 text-base'}>{qnaTitle}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>등록날짜</span>
                        <span className={'fs-14 text-base'}>{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>작성자 아이디</span>
                        <span className={'fs-14 text-base'}>{username}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>공개여부</span>
                        <span className={'fs-14 text-base'}>{qnaPublic ? '공개' : '비공개'}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>거주지역</span>
                        <span className={'fs-14 text-base'}>{qnaResidentialArea}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>성별</span>
                        <span className={'fs-14 text-base'}>{{MAN: '남성', WOMAN: '여성'}[qnaSex]}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>연령</span>
                        <span className={'fs-14 text-base'}>{qnaAge}{qnaAge !== '60' ? '대' : '대 이상'}</span>
                    </div>
                    {
                        qnaWorkType === 'WORKER' &&
                        <>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>근무형태</span>
                                <span className={'fs-14 text-base'}>근로자({{REGULAR: '정규직', IRREGULAR: '비정규직'}[qnaContractTerm]})</span>
                            </div>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>근로자수</span>
                                <span className={'fs-14 text-base'}>{{OVER_5: '5인 이상', UNDER_5: '5인 미만'}[qnaWorkerNum]}</span>
                            </div>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>재직기간</span>
                                <span className={'fs-14 text-base'}>{qnaWorkRange}</span>
                            </div>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>근무시간</span>
                                <span className={'fs-14 text-base'}>{qnaWorkTime}</span>
                            </div>
                        </>
                    }
                    {
                        qnaWorkType === 'MASTER' &&
                        <>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>근무형태</span>
                                <span className={'fs-14 text-base'}>사업주</span>
                            </div>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>근로자수</span>
                                <span className={'fs-14 text-base'}>{{OVER_5: '5인 이상', UNDER_5: '5인 미만'}[qnaWorkerNum]}</span>
                            </div>
                        </>
                    }
                    {
                        qnaWorkType === 'FREELANCER' &&
                        <>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>근무형태</span>
                                <span className={'fs-14 text-base'}>프리랜서</span>
                            </div>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>재직기간</span>
                                <span className={'fs-14 text-base'}>{qnaWorkRange}</span>
                            </div>
                            <div className={'d-flex flex-row border-bottom py-10'}>
                                <span className={'fs-14 text-gray mr-3'}>근무시간</span>
                                <span className={'fs-14 text-base'}>{qnaWorkTime}</span>
                            </div>
                        </>
                    }
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>사업장소재지</span>
                        <span className={'fs-14 text-base'}>{qnaBusinessPlace}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>업종</span>
                        <span className={'fs-14 text-base'}>{qnaBusinessType}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10'}>
                        <span className={'fs-14 text-gray mr-3'}>상담분야</span>
                        <span className={'fs-14 text-base'}>{{DELAY_WAGE: '임금체불', UNFAIR_DISMISSAL: '부당해고', NON_REGULAR: '비정규직', LEGAL: '생활법률', OTHER: '기타'}[qnaConsulting]}</span>
                    </div>
                    <div className={'d-flex flex-row border-bottom py-10 align-items-start'}>
                        <span className={'fs-14 text-gray mr-3'} style={{width: 56}}>내용</span>
                        <span className={'fs-14 text-base'}>{qnaContent}</span>
                    </div>
                    {
                        qnaFiles.length > 0 &&
                        <div className={'d-flex flex-row border-bottom py-10 align-items-start'}>
                            <span className={'fs-14 text-gray mr-3'}>첨부파일</span>
                            <div>
                                {
                                    qnaFiles.map((v, i) => <a key={i} className={'mr-3'} href={Apis.imageBasePath + v.path} target='_blank'>{v.name}</a>)
                                }
                            </div>
                        </div>
                    }
                </RctCollapsibleCard>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <div className={'fs-16 ff-dream4 mb-2'}>답변</div>
                    <Input
                        type={'textarea'} placeholder={'답변을 작성해주세요'} className={'fs-14'} style={{height: 170}}
                        value={qnaAnswer}
                        onChange={(e) => setQnaAnswer(e.target.value)}
                    />
                </RctCollapsibleCard>
            </DialogContent>
            <DialogActions className={'border-top'}>
                <Button variant={'outlined'} onClick={onClose}>취소</Button>
                <Button variant={'contained'} className={'text-white bg-blue'} onClick={onSave}>저장</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AnswerQnaModal;