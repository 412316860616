import React, {useState, useEffect, useRef} from "react";
import {Dialog, DialogContent, DialogActions, RadioGroup, Radio, FormControlLabel, Button } from "@material-ui/core";
import DialogTitle from "Components/Dialog/Title";
import {Input} from 'reactstrap';
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import ReactQuill from 'react-quill';
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

const modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ]
}

const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

function EditIssueModal({id, open, onClose}) {
    const inputFile = useRef();
    const [issueType, setIssueType] = useState('KNOWLEDGE');
    const [issuePublic, setIssuePublic] = useState(true);
    const [issueTitle, setIssueTitle] = useState('');
    const [issueImage, setIssueImage] = useState(null);
    const [issueContent, setIssueContent] = useState('');

    useEffect(() => {
        setIssueType('KNOWLEDGE');
        setIssuePublic(true)
        setIssueTitle('');
        setIssueImage(null);
        setIssueContent('');
        if(open && id !== '') getData();
    }, [open]);

    const getData = () => {
        Apis.issueInfo(id).then((resp) => {
            setIssueType(resp.issueType);
            setIssuePublic(resp.issuePublic);
            setIssueTitle(resp.issueTitle);
            setIssueImage(resp.issueImage);
            setIssueContent(resp.issueContent);
        }).catch(() => {});
    }

    const onAddImg = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files.length === 1) {
            setIssueImage(e.target.files[0]);
        }
    }

    const onSave = () => {
        if(issueTitle === '' || issueContent === '' || !issueImage) {
            NotificationManager.error('제목 및 내용을 입력하세요');
            return;
        }
        const data = {
            issueType,
            issuePublic,
            issueTitle,
            issueContent
        }
        let promise;
        if(typeof issueImage === 'object') {
            const formData = new FormData();
            formData.append('img', issueImage);
            promise = Apis.uploadImg('issue', formData);
        } else {
            promise = Promise.resolve();
        }
        promise.then((resp) => {
            if(!!resp) {
                data.issueImage = resp[0];
            }
            if (id === '') {
                return Apis.issueAdd(data);
            } else {
                return Apis.issueUpdate(id, data);
            }
        }).then((resp) => {
            NotificationManager.success('저장되었습니다.');
            onClose();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'}>
            <DialogTitle title={'글쓰기'} onClose={onClose}/>
            <DialogContent style={{width: 1000, height: 550}}>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <div className={'fs-13'}>카테고리</div>
                    <Input type={'select'} className={'fs-13 mr-2 mb-4'} style={{width: 170}} value={issueType} onChange={(e) => setIssueType(e.target.value)}>
                        <option value={'KNOWLEDGE'}>노동상식</option>
                        <option value={'ISSUE'}>노동이슈</option>
                        <option value={'LAW_INFO'}>법률정보</option>
                    </Input>
                    <div className={'fs-13 mt-1'}>진열여부</div>
                    <RadioGroup row value={issuePublic ? 'public' : 'private'} onChange={(e) => setIssuePublic(e.target.value === 'public')}>
                        <FormControlLabel value="public" control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>진열</span>}/>
                        <FormControlLabel value="private" control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>진열안함</span>}/>
                    </RadioGroup>
                    <div className={'fs-13 mt-1'}>제목</div>
                    <Input
                        type={'text'} className={'fs-13'} placeholder={'제목을 입력해주세요'}
                        value={issueTitle}
                        onChange={(e) => setIssueTitle(e.target.value)}
                    />
                    <div className={'fs-13 mt-4 mb-1'}>대표이미지</div>
                    <div className={'d-flex flex-row'}>
                        <div className={'mr-4'}>
                            <Button variant={'contained'} size={'small'} className={'bg-light-blue text-white'} onClick={() => inputFile.current.click()}>파일선택</Button>
                        </div>
                        {
                            issueImage &&
                            <div className={'issue-img'}>
                                <img
                                    src={typeof issueImage === 'string' ? Apis.apiAddress + '/upload/' + issueImage : URL.createObjectURL(issueImage)}
                                    alt={''}
                                />
                                <i className={'zmdi zmdi-close-circle-o'} onClick={() => setIssueImage(null)}/>
                            </div>
                        }
                    </div>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept="image/png, image/gif, image/jpeg" onChange={(e) => onAddImg(e)}/>
                    <div className={'fs-13 mt-4 mb-1'}>내용</div>
                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={issueContent}
                        onChange={(text) => setIssueContent(text)}
                    />
                </RctCollapsibleCard>
            </DialogContent>
            <DialogActions className={'border-top'}>
                <Button variant={'outlined'} onClick={onClose}>취소</Button>
                <Button variant={'contained'} className={'text-white bg-blue'} onClick={onSave}>저장</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditIssueModal;