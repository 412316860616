// routes
import Qna from 'Routes/qna';
import Issue from 'Routes/issue';
import Banner from 'Routes/banner';
import Link from 'Routes/link';
import Info from 'Routes/info';
import User from 'Routes/user';
import Profile from 'Routes/profile'

let routes = [
   {
      path: 'qna',
      component: Qna
   },
   {
      path: 'issue',
      component: Issue
   },
   {
      path: 'banner',
      component: Banner
   },
   {
      path: 'link',
      component: Link
   },
   {
      path: 'user',
      component: User
   },
   {
      path: 'info',
      component: Info
   },
   {
      path: 'profile',
      component: Profile
   },
]

export default routes;