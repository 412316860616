import React, {useState, useEffect} from "react";
import {Input} from "reactstrap";

const YearMonth = ({className, year, month, onChange}) => {
    const [selectedYear, setSelectedYear] = useState(year);
    const [selectedMonth, setSelectedMonth] = useState(month);

    useEffect(() => {
        setSelectedYear(year);
    }, [year]);

    useEffect(() => {
        setSelectedMonth(month)
    }, [month]);

    const onChangeYear = (year) => {
        setSelectedYear(year);
        setSelectedMonth('');
        onChange(year, '');
    }

    const onChangeMonth = ( month) => {
        setSelectedMonth(month);
        onChange(year, month)
    }

    return (
        <div className={'d-flex flex-row align-items-center' + className}>
            <Input
                type={'select'} className={'mr-1 p-1'} style={{width: 83}}
                value={selectedYear}
                onChange={(e) => onChangeYear(e.target.value)}
            >
                <option value={''}>전체</option>
                {
                    Array.from(Array(10).keys()).map((v) => (
                        <option value={v + 2021} key={v}>{v + 2021}</option>
                    ))
                }
            </Input>
            <Input
                type={'select'} className={'mr-2 px-1'} style={{width: 70}}
                value={selectedMonth}
                disabled={selectedYear === ''}
                onChange={(e) => onChangeMonth(e.target.value)}
            >
                <option value={''}>전체</option>
                {
                    Array.from(Array(12).keys()).map((v) => (
                        <option value={v + 1} key={v}>{String(v + 1).padStart(2, '0')}</option>
                    ))
                }
            </Input>
        </div>
    )
}

YearMonth.defaultProps = {
    onChange: () => null,
    year: 'all',
    month: 'all'
}

export default YearMonth;