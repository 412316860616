import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Input} from 'reactstrap';
import {Button} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import LinkItem from './LinkItem';
import * as Apis from 'Api';

const ItemList = () => {
    const history = useHistory();
    const [items, setItems] = useState([]);


    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.linkList().then((resp) => {
            setItems(resp);
        }).catch((e) => {
            console.error(e);
        });
    }

    return (
        <div className={'d-flex justify-content-center'}>
            <div className={'page-content'}>
                <Helmet>
                    <title>링크관리</title>
                </Helmet>
                <div className={'page-header'}>
                    <span>링크관리</span>
                </div>

                <div className={'page-table-container'}>
                    <div>
                        <div>
                            <div className={'text-gray fs-13'}>메인</div>
                            <LinkItem title={'인기검색어'} placeholder='인기검색어를 입력하세요' values={items}/>
                        </div>
                        <div>
                            <div className={'text-gray fs-13'}>왼쪽메뉴/메인</div>
                            <LinkItem title={'최저임금 계산기'} values={items}/>
                            <LinkItem title={'퇴직금 계산기'} values={items}/>
                            <LinkItem title={'실업급여 계산기'} values={items}/>
                            <LinkItem title={'연차휴가 계산기'} values={items}/>
                        </div>
                        <div className={'mt-50'}>
                            <div className={'text-gray fs-13'}>문의하기 페이지/패밀리사이트</div>
                            <LinkItem title={'고용노동부민원마당'} values={items}/>
                            <LinkItem title={'한국노총인천지역본부'} values={items}/>
                            <LinkItem title={'마음사이다 인천광역시 노사민정'} values={items}/>
                        </div>
                        <div className={'mt-50'}>
                            <div className={'text-gray fs-13'}>문의하기 페이지/SNS</div>
                            <LinkItem title={'블로그'} values={items}/>
                            <LinkItem title={'인스타'} values={items}/>
                            <LinkItem title={'유투브'} values={items}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemList;