/**
 * App.js Layout Start Here
 */
import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import RctThemeProvider from './RctThemeProvider';
import RctDefaultLayout from './DefaultLayout';
import AppSignIn from './Signin';
import Terms from 'Routes/nologin/terms';
import Privacy from 'Routes/nologin/privacy';

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const PrivateRoute = ({component: Component, ...rest}) => {
    const authUser = useSelector(state => state.authUser);
    const {isLogin} = authUser;
    return (
        <Route
            {...rest}
            render={props =>
                isLogin
                    ? <Component {...props} />
                    : <Redirect
                        to={{
                            pathname: '/signin',
                            state: {from: props.location}
                        }}
                    />}
        />
    );
}

function App(props) {
    const authUser = useSelector(state => state.authUser);
    const {user} = authUser;
    const {location, match} = props;
    if (location.pathname === '/') {
        if (user === null) {
            return (<Redirect to={'/signin'}/>);
        } else {
            return (<Redirect to={'/app/user'}/>);
        }
    }
    return (
        <RctThemeProvider>
            <NotificationContainer/>
            <PrivateRoute
                path={`${match.url}app`}
                component={RctDefaultLayout}
            />
            <Route path="/signin" component={AppSignIn}/>
            <Route path="/terms" component={Terms}/>
            <Route path="/privacy" component={Privacy}/>
        </RctThemeProvider>
    );
}

export default App;
