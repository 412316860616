import React, {useState, useEffect} from "react";
import {Dialog, DialogContent, DialogActions, Button} from "@material-ui/core";
import DialogTitle from 'Components/Dialog/Title';

function DeleteConfirmDialog({title, content, open, onClose, onConfirm}) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle title={title} onClose={onClose}/>
            <DialogContent className={'p-40'}>
                <span>{content}</span>
            </DialogContent>
            <DialogActions className={'border-top'}>
                <Button variant={'outlined'} size={'small'} onClick={onClose}>취소</Button>
                <Button variant={'contained'} className={'bg-blue text-white'} size={'small'} onClick={onConfirm}>확인</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteConfirmDialog;