import axios from 'axios';
import { Cookies } from 'react-cookie';
import queryString from 'query-string';

const cookie = new Cookies();

export default axios.create({
      baseURL: 'https://reactify.theironnetwork.org/data/',
      timeout: 2000
   });

function getApiHost()
{
   let hostname = window.location.hostname;
   if (
       hostname === 'localhost' ||
       /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostname)
   )
   {
      hostname = hostname + ':5000';
   }
   return window.location.protocol + '//' + hostname;
}

export const apiHost = getApiHost();

export const apiAddress = apiHost + '/api/';
export const imageBasePath = apiAddress + '/upload/';

export const accessToken = () => cookie.get("access_token");

const instance = axios.create({
   baseURL: apiAddress,
   timeout: 40000
});

instance.interceptors.request.use(function (config) {
   const token = cookie.get("access_token");
   config.headers.Authorization =  token ? `Bearer ${token}` : '';
   return config;
});

instance.interceptors.response.use(response => {
   return response;
}, error => {
   if (error.response.status === 401 && error.request.responseURL.indexOf('api/users/login') === -1) {
      // logout status
      cookie.remove('user_info', {path: '/'});
      cookie.remove('access_token', {path: '/'});
      window.location.href = '/signin';
   }
   throw error;
});

export function login(username, password) {
   const url = '/users/login';
   const req = {
      username,
      password,
      login_type: 'admin',
   };
   return instance.post(url, req).then((response) => response.data);
}

export function logout() {
   const url = '/users/logout';
   return instance.get(url).then((response) => response.data);
}

/**
 * user functions
 */
export function userList(page, count, search, sort) {
   const url = `/users?page=${page}&count=${count}&search=${search}&sort=${sort}`;
   return instance.get(url).then((response) => response.data);
}

export function userPassword(userId, newPassword) {
   const url = '/users/change-password';
   return instance.post(url, {userId, password: newPassword}).then((response) => response.data);
}

export function userActive(userId) {
   const url = '/users/active';
   return instance.post(url, {userId}).then((response) => response.data);
}

export function managerList(search) {
   const url = `/users/manager?search=${search}`;
   return instance.get(url).then((response) => response.data);
}

export function userInfo(id) {
   const url = `/users/${id}`;
   return instance.get(url).then((response) => response.data);
}

export function userUpdate(id, data) {
   const url = `/users/${id}`;
   return instance.patch(url, data).then((response) => response.data);
}

/**
 * qna functions
 */
export function qnaList(page, count, search, sort) {
   const url = `/qnas?page=${page}&count=${count}&search=${search}&sort=${sort}`;
   return instance.get(url).then((response) => response.data);
}

export function qnaInfo(id) {
   const url = `/qnas/${id}`;
   return instance.get(url).then((response) => response.data);
}

export function qnaAdd(data) {
   const url = `/qnas`;
   return instance.post(url, data).then((response) => response.data);
}

export function qnaUpdate(id, data) {
   const url = `/qnas/${id}`;
   return instance.patch(url, data).then((response) => response.data);
}

export function qnaAnswer(id, answer) {
   const url = `/qnas/${id}/answer`;
   return instance.post(url, {answer}).then((response) => response.data);
}

export function qnaDelete(id) {
   const url = `/qnas/${id}`;
   return instance.delete(url).then((response) => response.data);
}

export const qnaUploadFile = (data) => {
   const url = `/qnas/upload-file`;
   const config = {
      timeout: 0,
      headers: {
         'content-type': 'multipart/form-data'
      },
      // onUploadProgress: function (progressEvent) {
      //   let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //   onProgress(percent);
      // }
   };
   return instance.post(url, data, config).then((response) => response.data);
}

/**
 * issue functions
 */
export function issueList(page, count, search, sort) {
   const url = `/issues?page=${page}&count=${count}&search=${search}&sort=${sort}`;
   return instance.get(url).then((response) => response.data);
}

export function issueInfo(id) {
   const url = `/issues/${id}`;
   return instance.get(url).then((response) => response.data);
}

export function issueAdd(data) {
   const url = `/issues`;
   return instance.post(url, data).then((response) => response.data);
}

export function issueUpdate(id, data) {
   const url = `/issues/${id}`;
   return instance.patch(url, data).then((response) => response.data);
}

export function issueChangeShow(id) {
   const url = `/issues/${id}/show`;
   return instance.get(url).then((response) => response.data);
}

export function issueDelete(id) {
   const url = `/issues/${id}`;
   return instance.delete(url).then((response) => response.data);
}

/**
 * banner functions
 */
export function bannerList(page, count, sort) {
   const url = `/banners?page=${page}&count=${count}&sort=${sort}`;
   return instance.get(url).then((response) => response.data);
}

export function bannerInfo(id) {
   const url = `/banners/${id}`;
   return instance.get(url).then((response) => response.data);
}

export function bannerAdd(data) {
   const url = `/banners`;
   return instance.post(url, data).then((response) => response.data);
}

export function bannerUpdate(id, data) {
   const url = `/banners/${id}`;
   return instance.patch(url, data).then((response) => response.data);
}

export function bannerChangeShow(id) {
   const url = `/banners/${id}/show`;
   return instance.get(url).then((response) => response.data);
}

export function bannerDelete(id) {
   const url = `/banners/${id}`;
   return instance.delete(url).then((response) => response.data);
}


export const uploadImg = (path, data) => {
   const url = `/img/upload/${path}`;
   const config = {
      timeout: 0,
      headers: {
         'content-type': 'multipart/form-data'
      },
      // onUploadProgress: function (progressEvent) {
      //   let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //   onProgress(percent);
      // }
   };
   return instance.post(url, data, config).then((response) => response.data);
}

/**
 * setting functions
 */
export function privacyInfo() {
   const url = `/settings/privacy`;
   return instance.get(url).then((response) => response.data);
}

export function privacySet(value) {
   const url = `/settings/privacy`;
   return instance.patch(url, {value}).then((response) => response.data);
}

export function termsInfo() {
   const url = `/settings/terms`;
   return instance.get(url).then((response) => response.data);
}

export function termsSet(value) {
   const url = `/settings/terms`;
   return instance.patch(url, {value}).then((response) => response.data);
}

export function appIntroInfo() {
   const url = `/settings/appIntro`;
   return instance.get(url).then((response) => response.data);
}

export function appIntroSet(value) {
   const url = `/settings/appIntro`;
   return instance.patch(url, {value}).then((response) => response.data);
}

export function footerInfo() {
   const url = `/settings/footer`;
   return instance.get(url).then((response) => response.data);
}

export function footerSet(value) {
   const url = `/settings/footer`;
   return instance.patch(url, {value}).then((response) => response.data);
}

export function linkList() {
   const url = `/settings/links`;
   return instance.get(url).then((response) => response.data);
}

export function linkSave(id, value) {
   const url = `/settings/${id}`;
   return instance.patch(url, {value}).then((response) => response.data);
}

