import React, {useState, useEffect, useRef} from "react";
import {Dialog, DialogContent, DialogActions, RadioGroup, Radio, FormControlLabel, Button, IconButton} from "@material-ui/core";
import {Close} from '@material-ui/icons';
import DialogTitle from "Components/Dialog/Title";
import {Input} from 'reactstrap';
import {NotificationManager} from "react-notifications";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import JSONParseDefault from "json-parse-default";
import * as Apis from 'Api';

function AddQnaModal({open, onClose, id}) {
    const inputFile = useRef(null);
    const [qnaPublic, setQnaPublic] = useState(true);
    const [qnaTitle, setQnaTitle] = useState('');
    const [qnaResidentialArea, setQnaResidentialArea] = useState('');
    const [qnaSex, setQnaSex] = useState('');
    const [qnaAge, setQnaAge] = useState('');
    const [qnaWorkType, setQnaWorkType] = useState('WORKER');
    const [qnaContractTerm, setQnaContractTerm] = useState('REGULAR');
    const [qnaContractPeriod, setQnaContractPeriod] = useState('');
    const [qnaWorkerNum, setQnaWorkerNum] = useState('OVER_5');
    const [qnaWorkRange, setQnaWorkRange] = useState('');
    const [qnaWorkTime, setQnaWorkTime] = useState('');
    const [qnaBusinessPlace, setQnaBusinessPlace] = useState('');
    const [qnaBusinessType, setQnaBusinessType] = useState('');
    const [qnaPhone, setQnaPhone] = useState('');
    const [qnaConsulting, setQnaConsulting] = useState('DELAY_WAGE');
    const [qnaContent, setQnaContent] = useState('');
    const [qnaFiles, setQnaFiles] = useState([]);
    const [editable, setEditable] = useState(true);

    useEffect(() => {
        setQnaPublic(true)
        setQnaTitle('');
        setQnaResidentialArea('');
        setQnaSex('');
        setQnaAge('');
        setQnaWorkType('WORKER');
        setQnaContractTerm('REGULAR')
        setQnaContractPeriod('');
        setQnaWorkerNum('OVER_5')
        setQnaWorkRange('');
        setQnaWorkTime('');
        setQnaBusinessPlace('');
        setQnaBusinessType('');
        setQnaPhone('')
        setQnaConsulting('DELAY_WAGE');
        setQnaContent('');
        setEditable(true);
        setQnaFiles([]);
        if (open && id !== '') {
            getData();
        }
    }, [open]);

    useEffect(() => {
        if (qnaWorkType === 'WORKER') {
            setQnaContractTerm('REGULAR')
            setQnaContractPeriod('');
            setQnaWorkerNum('OVER_5')
            setQnaWorkRange('');
            setQnaWorkTime('');
        } else {
            setQnaContractTerm('')
            setQnaContractPeriod('');
            setQnaWorkerNum('')
            setQnaWorkRange('');
            setQnaWorkTime('');
        }
    }, [qnaWorkType]);


    const getData = () => {
        Apis.qnaInfo(id).then((resp) => {
            setQnaPublic(resp.qnaPublic);
            setQnaTitle(resp.qnaTitle);
            setQnaResidentialArea(resp.qnaResidentialArea);
            setQnaSex(resp.qnaSex);
            setQnaAge(resp.qnaAge);
            setQnaWorkType(resp.qnaWorkType);
            setQnaContractTerm(resp.qnaContractTerm)
            setQnaContractPeriod(resp.qnaContractPeriod);
            setQnaWorkerNum(resp.qnaWorkerNum);
            setQnaWorkRange(resp.qnaWorkRange);
            setQnaWorkTime(resp.qnaWorkTime);
            setQnaBusinessPlace(resp.qnaBusinessPlace);
            setQnaBusinessType(resp.qnaBusinessType);
            setQnaPhone(resp.qnaPhone)
            setQnaConsulting(resp.qnaConsulting);
            setQnaContent(resp.qnaContent);
            setQnaFiles(JSONParseDefault(resp.qnaFiles, null, []));
            setEditable(resp.qnaStatus !== 'FINISH');
        }).catch(() => {
        });
    }

    const onSave = async () => {
        if (qnaTitle === '') {
            NotificationManager.error('제목을 입력해야 합니다.');
            return false;
        }
        try {
            const data = {
                qnaPublic,
                qnaTitle,
                qnaResidentialArea,
                qnaSex,
                qnaAge,
                qnaWorkType,
                qnaContractTerm,
                qnaContractPeriod,
                qnaWorkerNum,
                qnaWorkRange,
                qnaWorkTime,
                qnaBusinessPlace,
                qnaBusinessType,
                qnaPhone,
                qnaConsulting,
                qnaContent
            }
            const needUploadFiles = qnaFiles.filter((v) => !(v && v.path));
            if (needUploadFiles.length > 0) {
                const formData = new FormData();
                needUploadFiles.forEach((v, i) => {
                    formData.append(i.toString(), v);
                })
                const resp = await Apis.qnaUploadFile(formData);
                if (!!resp) {
                    const files = qnaFiles.filter((v) => (v && v.path));
                    data.qnaFiles = JSON.stringify(files.concat(resp));
                }
            } else {
                data.qnaFiles = JSON.stringify([]);
            }
            if (id === '') {
                await Apis.qnaAdd(data);
            } else {
                await Apis.qnaUpdate(id, data);
            }
            NotificationManager.success('저장되었습니다.');
            onClose();
        } catch (e) {
            NotificationManager.error('오류가 발생했습니다.');
        }
    }

    const onSelectFile = (e) => {
        setQnaFiles([...qnaFiles, e.target.files[0]]);
    }

    const onRemoveFile = (index) => {
        qnaFiles.splice(index, 1);
        setQnaFiles([...qnaFiles]);
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'}>
            <DialogTitle title={'상담내역'} onClose={onClose}/>
            <DialogContent style={{width: 1000, height: 550}}>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <div className={'fs-16 ff-dream4 border-bottom'}>질문</div>
                    <div className={'fs-13 mt-3'}>공개여부</div>
                    <RadioGroup row value={qnaPublic ? 'public' : 'private'} onChange={(e) => setQnaPublic(e.target.value === 'public')}>
                        <FormControlLabel value="public" disabled={!editable} control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>공개</span>}/>
                        <FormControlLabel value="private" disabled={!editable} control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>비공개</span>}/>
                    </RadioGroup>
                    <div className={'fs-13 mt-1'}>제목</div>
                    <Input
                        type={'text'} className={'fs-13'} placeholder={'ex) 부당해고 문의드립니다.'}
                        disabled={!editable}
                        value={qnaTitle}
                        onChange={(e) => setQnaTitle(e.target.value)}
                    />
                    <div className={'fs-13 mt-3'}>거주지역<span className={'fs-11 text-light-blue ml-1'}>*비공개</span></div>
                    <Input
                        type={'text'} className={'fs-13'} placeholder={'ex) 인천시 부평구, 인천시 서구, 인천외지역'}
                        disabled={!editable}
                        value={qnaResidentialArea}
                        onChange={(e) => setQnaResidentialArea(e.target.value)}
                    />
                    <div className={'fs-13 mt-3'}>성별<span className={'fs-11 text-light-blue ml-1'}>*비공개</span></div>
                    <RadioGroup row value="public" value={qnaSex} onChange={(e) => setQnaSex(e.target.value)}>
                        <FormControlLabel value="MAN" disabled={!editable} control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>남성</span>}/>
                        <FormControlLabel value="WOMAN" disabled={!editable} control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>여성</span>}/>
                    </RadioGroup>
                    <div className={'fs-13 mt-2'}>연령<span className={'fs-11 text-light-blue ml-1'}>*비공개</span></div>
                    <Input type={'select'} className={'fs-13 mr-2 mb-4'} disabled={!editable} style={{width: 170}} value={qnaAge} onChange={(e) => setQnaAge(e.target.value)}>
                        {
                            [
                                {label: '10대', value: '10'},
                                {label: '20대', value: '20'},
                                {label: '30대', value: '30'},
                                {label: '40대', value: '40'},
                                {label: '50대', value: '50'},
                                {label: '60대 이상', value: '60'},
                            ].map((v) => <option value={v.value} key={v.value}>{v.label}</option>)
                        }
                    </Input>
                    <div className={'fs-13 mt-3'}>근무형태</div>
                    <RadioGroup row value="public" value={qnaWorkType} onChange={(e) => setQnaWorkType(e.target.value)}>
                        <FormControlLabel value="WORKER" disabled={!editable} control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>근로자</span>}/>
                        <FormControlLabel value="MASTER" disabled={!editable} control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>사업주</span>}/>
                        <FormControlLabel value="FREELANCER" disabled={!editable} control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>프리랜서</span>}/>
                    </RadioGroup>
                    {
                        qnaWorkType === 'WORKER' &&
                        <div>
                            <div className={'d-flex flex-row'}>
                                <Input
                                    type={'select'} className={'fs-13 mr-2'} style={{width: 170}}
                                    disabled={!editable}
                                    value={qnaContractTerm}
                                    onChange={(e) => {
                                        setQnaContractTerm(e.target.value);
                                        setQnaContractPeriod('')
                                    }}
                                >
                                    <option value={'REGULAR'}>정규직</option>
                                    <option value={'IRREGULAR'}>비정규직</option>
                                </Input>
                                {
                                    qnaContractTerm === 'IRREGULAR' &&
                                    <Input
                                        type={'text'} className={'fs-13'} placeholder={'ex) 계약기간 6개월'} style={{flex: 1}}
                                        disabled={!editable}
                                        value={qnaContractPeriod}
                                        onChange={(e) => setQnaContractPeriod(e.target.value)}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {
                        qnaWorkType !== 'FREELANCER' &&
                        <>
                            <div className={'fs-13 mt-3 mb-1'}>사업장근로자수</div>
                            <Input type={'select'} className={'fs-13 mr-2 mb-4'} disabled={!editable} style={{width: 170}} value={qnaWorkerNum} onChange={(e) => setQnaWorkerNum(e.target.value)}>
                                <option value={'OVER_5'}>5인 이상</option>
                                <option value={'UNDER_5'}>5인 미만</option>
                            </Input>
                        </>
                    }
                    {
                        qnaWorkType !== 'MASTER' &&
                        <div>
                            <div className={'fs-13 mb-1'}>재직기간</div>
                            <Input
                                type={'text'} className={'fs-13'} placeholder={'ex) 2년 3개월'}
                                disabled={!editable}
                                value={qnaWorkRange}
                                onChange={(e) => setQnaWorkRange(e.target.value)}
                            />
                            <div className={'fs-13 mt-4 mb-1'}>근무시간(선택)</div>
                            <Input
                                type={'text'} className={'fs-13'} placeholder={'ex) 09시 ~ 18시'}
                                disabled={!editable}
                                value={qnaWorkTime}
                                onChange={(e) => setQnaWorkTime(e.target.value)}
                            />
                        </div>
                    }
                    <div className={'fs-13 mt-4 mb-1'}>사업장 소재지<span className={'fs-11 text-light-blue ml-1'}>*비공개</span></div>
                    <Input
                        type={'text'} className={'fs-13'} placeholder={'ex) 인천시 부평구, 인천시 서구, 인천외지역'}
                        disabled={!editable}
                        value={qnaBusinessPlace}
                        onChange={(e) => setQnaBusinessPlace(e.target.value)}
                    />
                    <div className={'fs-13 mt-4 mb-1'}>업종<span className={'fs-11 text-light-blue ml-1'}>*비공개</span></div>
                    <Input
                        type={'text'} className={'fs-13'} placeholder={'ex)제조업, 건설업, 음식점업, 보건업 등'}
                        disabled={!editable}
                        value={qnaBusinessType}
                        onChange={(e) => setQnaBusinessType(e.target.value)}
                    />
                    <div className={'fs-13 mt-4 mb-1'}>연락처(선택)</div>
                    <Input
                        type={'text'} className={'fs-13'} placeholder={'ex) 010-1234-1234'}
                        disabled={!editable}
                        value={qnaPhone}
                        onChange={(e) => setQnaPhone(e.target.value)}
                    />
                    <div className={'fs-11 text-light-blue'}>통화를 원하시는 경우 연락처를 남겨주세요</div>
                    <div className={'fs-13 mt-4 mb-1'}>상담분야</div>
                    <Input type={'select'} className={'fs-13 mr-2'} disabled={!editable} style={{width: 170}} value={qnaConsulting} onChange={(e) => setQnaConsulting(e.target.value)}>
                        <option value={'DELAY_WAGE'}>임금체불</option>
                        <option value={'UNFAIR_DISMISSAL'}>부당해고</option>
                        <option value={'NON_REGULAR'}>비정규직</option>
                        <option value={'LEGAL'}>생활법률</option>
                        <option value={'OTHER'}>기타</option>
                    </Input>
                    <div className={'fs-13 mt-4 mb-1'}>문의내용</div>
                    <Input
                        type={'textarea'} className={'fs-13'} style={{height: 170}} placeholder={'궁금한 사항을 작성해주세요'}
                        disabled={!editable}
                        value={qnaContent}
                        onChange={(e) => setQnaContent(e.target.value)}
                    />
                    <div className={'fs-13 mt-4 mb-1'}>첨부파일</div>
                    <div className={'d-flex flex-row border px-4 py-2 mb-3'} style={{minHeight: 42, borderRadius: 5}}>
                        {
                            qnaFiles.map((v, i) => (
                                <div className={'mr-4'} key={i}>
                                    <span className={'fs-13'}>{v.name}</span>
                                    <IconButton size={'small'} onClick={() => onRemoveFile(i)}><Close fontSize={'small'}/></IconButton>
                                </div>
                            ))
                        }
                    </div>
                    <input type='file' id='file' ref={inputFile} accept=".jpg,.png,.hwp,.pdf" style={{display: 'none'}} onChange={onSelectFile}/>
                    <Button variant={'outlined'} style={{minWidth: 75}} size={'small'} className={'py-0'} onClick={() => qnaFiles.length < 2 && inputFile.current.click()}>파일첨부</Button>
                    <span className={'fs-12 ml-2'}>*10MB이하 jpg, png, hwp, pdf 파일만 업로드 가능합니다. (최대 2개)</span>

                </RctCollapsibleCard>
            </DialogContent>
            <DialogActions className={'border-top'}>
                <Button variant={'outlined'} onClick={onClose}>취소</Button>
                <Button variant={'contained'} className={'text-white bg-blue'} onClick={onSave} disabled={!editable}>저장</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddQnaModal;