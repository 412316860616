import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Input} from 'reactstrap';
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import {Button} from "@material-ui/core";

const Index = () => {
    return (
        <div className={'d-flex justify-content-center'}>
            <div className={'page-content'}>
                <Helmet>
                    <title>정보수정</title>
                </Helmet>
                <div className={'page-header'}>
                    <span>정보수정</span>
                </div>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <span className={'fs-14 ff-dream5'}>관리자암호변경</span>
                    <div className={'row mt-4'}>
                        <div className={'col-4 text-nowrap'}>
                            <div className={'d-flex flex-row align-items-center mb-2'}>
                                <span className={'fs-13 mr-3'}>본래암호</span>
                                <Input type={'password'} className={'fs-13'} />
                            </div>
                            <div className={'d-flex flex-row align-items-center mb-2'}>
                                <span className={'fs-13 mr-3'} style={{paddingRight: 13}}>새암호</span>
                                <Input type={'password'} className={'fs-13'} />
                            </div>
                            <div className={'d-flex flex-row align-items-center mb-2'}>
                                <span className={'fs-13 mr-3'}>암호확인</span>
                                <Input type={'password'} className={'fs-13'} />
                            </div>
                            <div className={'d-flex flex-row justify-content-end mt-5'}>
                                <Button variant={'contained'} className={'bg-red text-white'}>변경</Button>
                            </div>
                        </div>
                    </div>
                </RctCollapsibleCard>
            </div>
        </div>
    )
}

export default Index;