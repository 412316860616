import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Input} from 'reactstrap';
import {Button, IconButton} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import PaginationComponent from "Components/PaginationComponent";
import EditBannerModal from "./EditBannerModal";
import moment from 'moment';
import * as Apis from 'Api';
import DeleteConfirmDialog from "../../Components/DeleteConfirmDialog";
import {bannerList} from "Api";
import classNames from "classnames";

const ItemList = () => {
    const history = useHistory();
    const [itemList, setItemList] = useState([]);
    const [sortField, setSortField] = useState({field: 'bannerOrder', asc: true});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [editId, setEditId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);


    useEffect(() => {
        getData();
    }, [currentPage, itemsPerPage, sortField]);

    const getData = () => {
        Apis.bannerList(currentPage, itemsPerPage, JSON.stringify(sortField)).then((resp) => {
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onShowChange = (id) => {
        Apis.bannerChangeShow(id).then((resp) => {
            NotificationManager.success('변경되었습니다.');
            getData();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    const onDelete = () => {
        const delId = deleteId;
        setDeleteId(null);
        Apis.bannerDelete(delId).then((resp) => {
            NotificationManager.success('삭제했습니다.');
            getData();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    const onChangeSort = (field) => {
        setSortField({field, asc: (sortField.field === field ? !sortField.asc : true)})
    }

    const renderTableHeader = (name, field) => {
        let iconClass;
        if (sortField.field === field) {
            iconClass = sortField.asc ? 'zmdi-long-arrow-up' : 'zmdi-long-arrow-down'
        }
        return (
            <div onClick={() => onChangeSort(field)} className={'cursor-pointer '}>
                {name} {iconClass && <i className={"zmdi ml-1 fs-12 " + iconClass}/>}
            </div>
        )
    }

    return (
        <div className={'d-flex justify-content-center'}>
            <div className={'page-content'}>
                <Helmet>
                    <title>메인배너</title>
                </Helmet>
                <div className={'page-header'}>
                    <span>메인배너</span>
                </div>
                
                <div className={'page-table-container'}>
                    <div className={'page-table-header'}>
                        <span>검색결과: {totalItemCount}건</span>
                        <div className={'d-flex flex-row align-items-center text-nowrap'}>
                            <span className={'mr-2'}>목록개수</span>
                            <div>
                                <Input type={'select'} className={'mr-2'} value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </Input>
                            </div>
                            <Button
                                variant={'contained'}
                                className={'text-white bg-blue ml-2'}
                                size={'small'}
                                onClick={() => setEditId('')}
                            >
                                배너추가
                            </Button>
                        </div>
                    </div>
                    <div className={'banner-list'}>
                        <div className={'banner-header'}>
                            <div className={'text-center'}>{renderTableHeader('진열순서', 'bannerOrder')}</div>
                            <div className={'text-center'}>{renderTableHeader('배너위치', 'bannerType')}</div>
                            <div className={'text-center'}>이미지</div>
                            <div className={'text-center'}>앱 이미지</div>
                            <div className={'text-center'}>{renderTableHeader('연결주소(URL)', 'bannerLink')}</div>
                            <div className={'text-center'}>관리</div>
                        </div>
                        <div className={'banner-content'}>
                            {
                                itemList.map((v) => (
                                    <div className={'banner-row'} key={v.id}>
                                        <div className={'text-center'}>{v.bannerOrder}</div>
                                        <div className={'text-center'}>{{MAIN_BANNER: '메인', SUB_BANNER: '서브'}[v.bannerType]}</div>
                                        <div className={'text-center'}><img src={Apis.apiAddress + '/upload/' + v.bannerImage} alt={''} /></div>
                                        <div className={'text-center'}>{v.bannerAppImage && <img src={Apis.apiAddress + '/upload/' + v.bannerAppImage} alt={''} />}</div>
                                        <div className={'text-center'} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{v.bannerLink}</div>
                                        <div className={'text-center'}>
                                            <IconButton className={'mr-1'} onClick={() => onShowChange(v.id)}>
                                                <i className={classNames('zmdi', {'zmdi-eye': v.bannerShow}, {'zmdi-eye-off': !v.bannerShow})} />
                                            </IconButton>
                                            <Button variant={'outlined'} size={'small'} onClick={() => setDeleteId(v.id)}>삭제</Button>
                                            <Button variant={'contained'} className={'bg-light-blue text-white ml-2'} size={'small'} onClick={() => setEditId(v.id)}>수정</Button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <PaginationComponent
                            totalItems={totalItemCount}
                            pageSize={itemsPerPage}
                            onSelect={(page) => setCurrentPage(page)}
                            maxPaginationNumbers={7}
                            activePage={currentPage}
                        />
                    </div>
                </div>
            </div>
            <EditBannerModal open={editId !== null} id={editId} onClose={() => {setEditId(null); getData();}} />
            <DeleteConfirmDialog title={'배너 삭제'} content={'배너를 정말로 삭제하시겠습니까?'} open={deleteId !== null} onClose={() => setDeleteId(null)} onConfirm={onDelete} />
        </div>
    )
}

export default ItemList;