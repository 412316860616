import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Input} from 'reactstrap';
import {Button} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import YearMonth from "Components/Widgets/YearMonth";
import PaginationComponent from "Components/PaginationComponent";
import moment from 'moment';
import * as Apis from 'Api';

const ItemList = () => {
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [itemList, setItemList] = useState([]);
    const [sortField, setSortField] = useState({field: 'createdAt', asc: true});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);



    useEffect(() => {
        getData();
    }, [currentPage, itemsPerPage, sortField]);

    const getData = () => {
        const search = {
            text: searchText !== '' ? searchText : undefined,
        };
        Apis.userList(currentPage, itemsPerPage, JSON.stringify(search), JSON.stringify(sortField)).then((resp) => {
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onChangeSort = (field) => {
        setSortField({field, asc: (sortField.field === field ? !sortField.asc : true)})
    }

    const renderTableHeader = (name, field) => {
        let iconClass;
        if (sortField.field === field) {
            iconClass = sortField.asc ? 'zmdi-long-arrow-up' : 'zmdi-long-arrow-down'
        }
        return (
            <div onClick={() => onChangeSort(field)} className={'cursor-pointer '}>
                {name} {iconClass && <i className={"zmdi ml-1 fs-12 " + iconClass}/>}
            </div>
        )
    }

    return (
        <div className={'d-flex justify-content-center'}>
            <div className={'page-content'}>
                <Helmet>
                    <title>회원관리</title>
                </Helmet>
                <div className={'page-header'}>
                    <span>회원관리</span>
                </div>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <div className={'d-flex flex-row justify-content-between align-items-center text-nowrap mb-3'}>
                        <div className={'d-flex search-bar ml-4'}>
                            <i className={'zmdi zmdi-search'}/>
                            <Input
                                type={'text'}
                                className={'mr-2 fs-13'}
                                placeholder={'검색어'}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button variant={'contained'} size={'small'} onClick={getData}>검색</Button>
                        </div>
                    </div>
                </RctCollapsibleCard>
                <div className={'page-table-container'}>
                    <div className={'page-table-header'}>
                        <span>검색결과: {totalItemCount}건</span>
                        <div className={'d-flex flex-row align-items-center text-nowrap'}>
                            <span className={'mr-2'}>목록개수</span>
                            <div>
                                <Input type={'select'} className={'mr-2'} value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </Input>
                            </div>
                        </div>
                    </div>
                    <table className={'main-table'}>
                        <thead>
                        <tr>
                            <th>{renderTableHeader('가입종류', 'signupType')}</th>
                            <th>{renderTableHeader('아이디(이메일)', 'email')}</th>
                            <th>{renderTableHeader('이름', 'username')}</th>
                            <th>{renderTableHeader('가입일', 'createdAt')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            itemList.map((v) => (
                                <tr key={v.id}>
                                    <td>{v.signupType === 'NAVER' ? "네이버 간편가입" : "일반가입"}</td>
                                    <td>{v.email}</td>
                                    <td>{v.username}</td>
                                    <td>{moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ItemList;