import React, {useState, useEffect} from "react";
import {DialogTitle, IconButton} from '@material-ui/core';

function Title({title, onClose}) {
    return (
        <DialogTitle style={{backgroundColor: '#324c6e'}}>
            <div className={'d-flex flex-row justify-content-between align-items-center'} style={{backgroundColor: '#324c6e'}}>
                <span className={'fs-16 ff-dream5 text-white'}>{title}</span>
                <IconButton size={'small'} className={'p-1 text-white'} onClick={onClose}><i className={'fs-17 zmdi zmdi-close'}/> </IconButton>
            </div>
        </DialogTitle>
    )
};

export default Title;