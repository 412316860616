import React, {useState, useEffect, useRef} from "react";
import {Dialog, DialogContent, DialogActions, RadioGroup, Radio, FormControlLabel, Button} from "@material-ui/core";
import DialogTitle from "Components/Dialog/Title";
import {Input} from 'reactstrap';
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

function EditBannerModal({id, open, onClose}) {
    const inputFile = useRef();
    const inputAppFile = useRef();
    const [bannerType, setBannerType] = useState('MAIN_BANNER');
    const [bannerShow, setBannerShow] = useState(true);
    const [bannerOrder, setBannerOrder] = useState(1);
    const [bannerLink, setBannerLink] = useState('');
    const [bannerImage, setBannerImage] = useState(null);
    const [bannerAppImage, setBannerAppImage] = useState(null);

    useEffect(() => {
        setBannerType('MAIN_BANNER');
        setBannerShow(true);
        setBannerOrder(1);
        setBannerLink('');
        setBannerImage(null);
        setBannerAppImage(null);
        if (open && id !== '') getData();
    }, [open]);

    const getData = () => {
        Apis.bannerInfo(id).then((resp) => {
            setBannerType(resp.bannerType);
            setBannerShow(resp.bannerShow);
            setBannerOrder(resp.bannerOrder);
            setBannerLink(resp.bannerLink);
            setBannerImage(resp.bannerImage);
            setBannerAppImage(resp.bannerAppImage);
        }).catch(() => {
        });
    }

    const onAddImg = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files.length === 1) {
            setBannerImage(e.target.files[0]);
        }
    }

    const onAddAppImg = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files.length === 1) {
            setBannerAppImage(e.target.files[0]);
        }
    }

    const onSave = async () => {
        if (!bannerOrder || bannerLink === '' || bannerImage === null) {
            NotificationManager.error('내용을 입력하세요.');
            return;
        }
        const data = {
            bannerType,
            bannerShow,
            bannerOrder: parseInt(bannerOrder),
            bannerLink,
            bannerImage,
            bannerAppImage
        }
        try {
            if (typeof bannerImage === 'object') {
                const formData = new FormData();
                formData.append('img', bannerImage);
                const resp = await Apis.uploadImg('banner', formData);
                if (!!resp) data.bannerImage = resp[0];
            }
            if (typeof bannerAppImage === 'object') {
                const formData = new FormData();
                formData.append('img', bannerAppImage);
                const resp = await Apis.uploadImg('banner', formData);
                if (!!resp) data.bannerAppImage = resp[0];
            }
            if (id === '') {
                await Apis.bannerAdd(data);
            } else {
                await Apis.bannerUpdate(id, data)
            }
            NotificationManager.success('저장되었습니다.');
            onClose();
        } catch (e) {
            NotificationManager.error('오류가 발생했습니다.');
        }

        // let promise;
        // if(typeof bannerImage === 'object') {
        //     const formData = new FormData();
        //     formData.append('img', bannerImage);
        //     promise = Apis.uploadImg('banner', formData);
        // } else {
        //     promise = Promise.resolve();
        // }
        // promise.then((resp) => {
        //     if(!!resp) {
        //         data.bannerImage = resp[0];
        //     }
        //     if (id === '') {
        //         return Apis.bannerAdd(data);
        //     } else {
        //         return Apis.bannerUpdate(id, data)
        //     }
        // }).then((resp) => {
        //     NotificationManager.success('저장되었습니다.');
        //     onClose();
        // }).catch((e) => {
        //     NotificationManager.error('오류가 발생했습니다.');
        // });
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'}>
            <DialogTitle title={'메인배너'} onClose={onClose}/>
            <DialogContent style={{width: 700, height: 450}}>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <div className={'fs-13 mt-1'}>배너위치</div>
                    <RadioGroup row value={bannerType} onChange={(e) => setBannerType(e.target.value)}>
                        <FormControlLabel value="MAIN_BANNER" control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>메인배너</span>}/>
                        <FormControlLabel value="SUB_BANNER" control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>서브배너</span>}/>
                    </RadioGroup>
                    <div className={'fs-13 mt-1'}>진열여부</div>
                    <RadioGroup row value={bannerShow ? 'show' : 'hide'} onChange={(e) => setBannerShow(e.target.value === 'show')}>
                        <FormControlLabel value="show" control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>진열</span>}/>
                        <FormControlLabel value="hide" control={<Radio color="primary"/>} label={<span className={'fs-14 ff-dream4'}>진열안함</span>}/>
                    </RadioGroup>
                    <div className={'fs-13 mt-1'}>진열순서</div>
                    <Input
                        type={'number'} className={'fs-13'} placeholder={'진열순서를 숫자로 입력해주세요'}
                        value={bannerOrder}
                        onChange={(e) => setBannerOrder(e.target.value)}
                    />
                    <div className={'fs-13 mt-4'}>연결(URL)</div>
                    <Input
                        type={'text'} className={'fs-13'} placeholder={'이동할 링크 주소(URL)를 입력해 주세요.'}
                        value={bannerLink}
                        onChange={(e) => setBannerLink(e.target.value)}
                    />
                    <div className={'fs-13 mt-4 mb-1'}>배너이미지</div>
                    <div className={'d-flex flex-column'}>
                        <div className={'mb-2'}>
                            <Button variant={'contained'} size={'small'} className={'bg-light-blue text-white'} onClick={() => inputFile.current.click()}>파일선택</Button>
                        </div>
                        {
                            bannerImage &&
                            <img src={typeof bannerImage === 'string' ? Apis.apiAddress + '/upload/' + bannerImage : URL.createObjectURL(bannerImage)} alt={''}/>
                        }
                    </div>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept="image/png, image/gif, image/jpeg" onChange={(e) => onAddImg(e)}/>
                    {
                        bannerType === 'MAIN_BANNER' &&
                        <div>
                            <div className={'fs-13 mt-4 mb-1'}>앱 배너이미지</div>
                            <div className={'d-flex flex-column'}>
                                <div className={'mb-2'}>
                                    <Button variant={'contained'} size={'small'} className={'bg-light-blue text-white'} onClick={() => inputAppFile.current.click()}>파일선택</Button>
                                </div>
                                {
                                    bannerAppImage &&
                                    <img src={typeof bannerAppImage === 'string' ? Apis.apiAddress + '/upload/' + bannerAppImage : URL.createObjectURL(bannerAppImage)} alt={''}/>
                                }
                            </div>
                            <input type='file' id='app_file' ref={inputAppFile} style={{display: 'none'}} accept="image/png, image/gif, image/jpeg" onChange={(e) => onAddAppImg(e)}/>
                        </div>
                    }

                </RctCollapsibleCard>
            </DialogContent>
            <DialogActions className={'border-top'}>
                <Button variant={'outlined'} onClick={onClose}>취소</Button>
                <Button variant={'contained'} className={'text-white bg-blue'} onClick={onSave}>저장</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditBannerModal;