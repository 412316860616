import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Input} from 'reactstrap';
import {Button, IconButton} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import YearMonth from "Components/Widgets/YearMonth";
import PaginationComponent from "Components/PaginationComponent";
import DeleteConfirmDialog from 'Components/DeleteConfirmDialog';
import EditIssueModal from './EditIssueModal';
import moment from 'moment';
import * as Apis from 'Api';
import classNames from "classnames";
import {issueChangeShow, issueDelete} from "Api";

const ItemList = () => {
    const history = useHistory();
    const [searchStatus, setSearchStatus] = useState('');
    const [searchYear, setSearchYear] = useState('');
    const [searchMonth, setSearchMonth] = useState('');
    const [searchText, setSearchText] = useState('');
    const [itemList, setItemList] = useState([]);
    const [sortField, setSortField] = useState({field: 'createdAt', asc: false});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [editId, setEditId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        getData();
    }, [currentPage, itemsPerPage, sortField]);

    const getData = () => {
        const search = {
            text: searchText !== '' ? searchText : undefined,
            issueType: searchStatus !== '' ? searchStatus : undefined,
            year: searchYear !== '' ? searchYear : undefined,
            month: searchMonth !== '' ? searchMonth : undefined,
        };
        Apis.issueList(currentPage, itemsPerPage, JSON.stringify(search), JSON.stringify(sortField)).then((resp) => {
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onShowChange = (id) => {
        Apis.issueChangeShow(id).then((resp) => {
            NotificationManager.success('변경되었습니다.');
            getData();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    const onDelete = () => {
        const delId = deleteId;
        setDeleteId(null);
        Apis.issueDelete(delId).then((resp) => {
            NotificationManager.success('삭제했습니다.');
            getData();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    const onChangeSort = (field) => {
        setSortField({field, asc: (sortField.field === field ? !sortField.asc : true)})
    }

    const renderTableHeader = (name, field) => {
        let iconClass;
        if (sortField.field === field) {
            iconClass = sortField.asc ? 'zmdi-long-arrow-up' : 'zmdi-long-arrow-down'
        }
        return (
            <div onClick={() => onChangeSort(field)} className={'cursor-pointer '}>
                {name} {iconClass && <i className={"zmdi ml-1 fs-12 " + iconClass}/>}
            </div>
        )
    }

    return (
        <div className={'d-flex justify-content-center'}>
            <div className={'page-content'}>
                <Helmet>
                    <title>게시판</title>
                </Helmet>
                <div className={'page-header'}>
                    <span>게시판</span>
                </div>
                <RctCollapsibleCard fullBlock contentCustomClasses={'p-3'}>
                    <div className={'d-flex flex-row justify-content-between align-items-center text-nowrap mb-3'}>
                        <div className={'d-flex flex-row align-items-center'}>
                            <span className={'fs-13 mr-1'}>카테고리</span>
                            <Input
                                type={'select'} className={'fs-13'}
                                value={searchStatus}
                                onChange={(e) => setSearchStatus(e.target.value)}
                            >
                                <option value={''}>전체</option>
                                <option value={'ISSUE'}>노동이슈</option>
                                <option value={'KNOWLEDGE'}>노동상식</option>
                                <option value={'LAW_INFO'}>법률정보</option>
                            </Input>
                            <span className={'fs-13 ml-4 mr-1'}>등록일자</span>
                            <YearMonth
                                className={'fs-13'}
                                year={searchYear}
                                month={searchMonth}
                                onChange={(year, month) => {setSearchYear(year); setSearchMonth(month)}}
                            />
                        </div>
                        <div className={'d-flex search-bar ml-4'}>
                            <i className={'zmdi zmdi-search'}/>
                            <Input
                                type={'text'}
                                className={'mr-2 fs-13'}
                                placeholder={'검색어'}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button variant={'contained'} size={'small'} onClick={getData}>검색</Button>
                        </div>
                    </div>
                </RctCollapsibleCard>
                <div className={'page-table-container'}>
                    <div className={'page-table-header'}>
                        <span>검색결과: {totalItemCount}건</span>
                        <div className={'d-flex flex-row align-items-center text-nowrap'}>
                            <span className={'mr-2'}>목록개수</span>
                            <div>
                                <Input type={'select'} className={'mr-2'} value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </Input>
                            </div>
                            <Button
                                variant={'contained'}
                                className={'text-white bg-blue ml-2'}
                                size={'small'}
                                onClick={() => setEditId('')}
                            >
                                글쓰기
                            </Button>
                        </div>
                    </div>
                    <table className={'main-table'}>
                        <thead>
                        <tr>
                            <th>{renderTableHeader('등록일자', 'createdAt')}</th>
                            <th>{renderTableHeader('카테고리', 'issueType')}</th>
                            <th>{renderTableHeader('제목', 'issueTitle')}</th>
                            <th>관리</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            itemList.map((v) => (
                                <tr key={v.id}>
                                    <td>{moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>{{KNOWLEDGE: '노동상식', ISSUE: '노동이슈', LAW_INFO: '법률정보'}[v.issueType]}</td>
                                    <td><span className={'text-underline cursor-pointer'} onClick={() => setEditId(v.id)}>{v.issueTitle}</span></td>
                                    <td>
                                        <IconButton className={'mr-1'} onClick={() => onShowChange(v.id)}>
                                            <i className={classNames('zmdi', {'zmdi-eye': v.issuePublic}, {'zmdi-eye-off': !v.issuePublic})} />
                                        </IconButton>
                                        <Button variant={'outlined'} size={'small'} onClick={() => setDeleteId(v.id)}>삭제</Button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <EditIssueModal open={editId !== null} id={editId} onClose={() => {setEditId(null); getData();}} />
            <DeleteConfirmDialog title={'게시글 삭제'} content={'게시글을 정말로 삭제하시겠습니까?'} open={deleteId !== null} onClose={() => setDeleteId(null)} onConfirm={onDelete} />
        </div>
    )
}

export default ItemList;