import React, {useState, useEffect} from 'react'
import renderHtml from 'react-render-html';
import * as Apis from 'Api';

function Terms() {
    const [text, setText] = useState('');

    useEffect(() => {
        Apis.termsInfo().then((resp) => {
            setText(resp.value);
        })
    }, []);


    return (
        <div className={'rct-session-wrapper'} style={{height: '100%', overflow: 'auto'}}>
            <div className="container" style={{marginTop: 50}}>
                {renderHtml(text)}
            </div>
        </div>
    )
}

export default Terms;