// sidebar nav links
let sidebarMenu = {
    category1: [
        {
            "menu_title": "상담내역",
            "menu_icon": "qna.png",
            "path": "/app/qna"
        },
        {
            "menu_title": "게시판",
            "menu_icon": "issue.png",
            "path": "/app/issue"
        },
        {
            "menu_title": "메인배너",
            "menu_icon": "banner.png",
            "path": "/app/banner"
        },
        {
            "menu_title": "링크관리",
            "menu_icon": "link.png",
            "path": "/app/link"
        },
    ],
    category2: [
        {
            "menu_title": "회원 관리",
            "menu_icon": "user.png",
            "path": "/app/user"
        }
    ],
    category3: [
        {
            "menu_title": "개인정보처리방침",
            "menu_icon": "terms.png",
            "path": "/app/info/privacy"
        },
        {
            "menu_title": "이용약관",
            "menu_icon": "terms.png",
            "path": "/app/info/terms"
        },
        {
            "menu_title": "앱 소개",
            "menu_icon": "info.png",
            "path": "/app/info/app"
        }
    ],
    category4: [
        {
            "menu_title": "사이트 하단",
            "menu_icon": "footer.png",
            "path": "/app/info/footer"
        }
    ]
}

export default sidebarMenu