import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from '@material-ui/core';
import {Link, NavLink} from 'react-router-dom';
import {FormGroup, Input} from 'reactstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import {NotificationManager} from "react-notifications";
import {signinUser,} from 'Store/Actions';
import * as Apis from "Api";

function Signin(props) {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    /**
     * On User Login
     */
    const onUserLogin = () => {
        setLoading(true);
        if (id !== '' && password !== '') {
            Apis.login(id, password).then((result) => {
                setLoading(false);
                dispatch(signinUser(result.info, result.token, props.history));
            }).catch((e) => {
                NotificationManager.error('아이디나 암호가 정확하지 않습니다');
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return (
        <div className={'login-container'}>
            {loading &&
                <LinearProgress/>
            }
            <div className={'login-content'}>
                <div className={'form-container'}>
                    <div className={'form-content'}>
                        <img src={require('Assets/img/main-logo.png').default} alt={''} className={'login-logo'}/>
                        <span className={'login-title ff-dream4'}>인천광역시 무료 노동상담</span>
                        <span className={'ff-dream6 fs-16 text-center mb-4'}>관리페이지</span>
                        <FormGroup className="login-input">
                            <Input
                                type="mail"
                                placeholder="아이디(이메일)"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                            <i className="zmdi zmdi-account"/>
                        </FormGroup>
                        <FormGroup className="login-input">
                            <Input
                                type="password"
                                placeholder="비밀번호"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <i className="zmdi zmdi-lock"/>
                        </FormGroup>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            className={'login-btn'}
                            onClick={onUserLogin}
                        >
                            로그인
                        </Button>
                    </div>
                </div>
                <div className={'login-footer'}>
                    <div className={'d-flex text-nowrap flex-row align-items-center'}>
                    </div>
                    <div className={'d-flex flex-row align-items-center'}>
                        <NavLink to={'/terms'} className={'fs-13 text-white'}>개인정보처리방침</NavLink>
                        <span className={'text-white fs-16 px-2'}>|</span>
                        <NavLink to={'/privacy'} className={'fs-13 text-white'}>이용약관</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signin;
