import React, {useState, useEffect} from "react";
import ReactQuill from 'react-quill';
import {Helmet} from "react-helmet";
import {Button} from '@material-ui/core';
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

const modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ]
}

const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

const Privacy = () => {
    const [text, setText] = useState('');

    useEffect(() => {
        Apis.appIntroInfo().then((resp) => {
            setText(resp.value);
        })
    }, []);

    const onSave = () => {
        Apis.appIntroSet(text).then((resp) => {
            NotificationManager.success('저장되었습니다.');
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        })
    }

    return (
        <div className={'d-flex justify-content-center'}>
            <div className={'page-content'}>
                <Helmet>
                    <title>앱 소개</title>
                </Helmet>
                <div className={'page-header'}>
                    <span>앱 소개</span>
                </div>
                <RctCollapsibleCard>
                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={text}
                        onChange={setText}
                    />
                    <div className={'d-flex flex-row justify-content-end mt-3'}>
                        <Button variant={'contained'} className={'bg-red text-white'} onClick={onSave}>저장</Button>
                    </div>
                </RctCollapsibleCard>
            </div>
        </div>
    )
}

export default Privacy;